import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCertificateImage } from '../../redux/postPaymentSlice'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'

const CertificateImageModalInside = ({ handleModal, setShowCertificateImageModalInside }) => {

    const certificateImage = useSelector(selectCertificateImage)

    const courseImage = certificateImage === 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2022085%20(1).png?updatedAt=1729606706291'

    useEffect(() => {
        Mixpanel.track(courseImage ? constants.Mixpanel.COURSE_CERTIFICATE_MODAL_OPENED : constants.Mixpanel.CERTIFICATE_MODAL_OPENED)
    }, [courseImage])

    return (
        <div className='p-3'>
            <div className='flex gap-2 items-center justify-between mb-3'>
                {(setShowCertificateImageModalInside) && <div className='cursor-pointer' 
                    onClick={() =>{
                        setShowCertificateImageModalInside(false)   
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className='text-2xl'/>
                </div>}
                <h3 className='text-xl font-bold flex-1 mx-auto'>{courseImage ? 'Certificate Of Completion' : 'Certificate Of Assessment'}</h3>
                <div className='cursor-pointer' onClick={handleModal}>
                    <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                </div>
            </div>
            <img src={certificateImage} alt="" />
        </div>
    )
}

export default CertificateImageModalInside